.iconPrimary {
  color: var(--bs-primary);
  border-radius: 50%;
}

.thumbnailImage {
  width: 50px;
  height: 50px;
}

.thumbnailSmallImage {
  width: 35px;
  height: 35px;
}

.dropdownTitleText {
  color: var(--bs-primary);
  font-size: 1.4em;
}

.secondaryText {
  color: grey;
  font-size: 0.8em;
}

.smallText {
  color: grey;
  font-size: 0.6em;
}

.smallBoldText {
  color: grey;
  font-weight: bold;
  font-size: 0.7em;
}

.iconRed {
  color: var(--bs-danger);
  border-radius: 50%;
}

.iconGreen {
  color: var(--bs-success);
  border-radius: 50%;
}

.stickyBottom {
  bottom: 0;
  position: sticky;
  z-index: 1000;
  background: white;
}

:global {
  @media (width < 400px) {
    body {
      font-size: 0.9em;
    }
  }
}

.tableHeader {
  border-bottom: solid 3px rgba(0, 0, 0, 0.14);
}

.tableHeader th {
  color: rgba(0, 0, 0, 0.6);
  align-content: end;
}

.textAlignCenter {
  text-align: center;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.rotateOpen {
  transition: transform 150ms ease;
  transform: rotate(180deg);
}

.rotateClosed {
  transition: transform 150ms ease;
  transform: rotate(0deg);
}
