.list {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 300px);
}

.tab {
  flex: 1 1 auto;
  overflow: scroll;
}
