// https://create-react-app.dev/docs/adding-a-sass-stylesheet/
// https://getbootstrap.com/docs/4.2/getting-started/theming

$primary: #235c9e;
$secondary: #04c4d9;
$danger: #ed2626;
$success: #377a26;
$enable-rounded: false;

@import '../node_modules/bootstrap/scss/bootstrap';

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}
