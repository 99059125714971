@import '../Common.module';

.border {
  border: 2px solid var(--bs-border-color);
}

.icon {
  color: var(--bs-primary);
}

.pointer {
  @extend .iconClickable;
  cursor: pointer;
}

.customDropdown {
  box-shadow: 0 0 0 0.1rem rgba(35, 92, 158, 0.25);
  border: 2px solid var(--bs-primary-border-subtle);
}
