.rightBorder {
  border-right: 1px solid var(--bs-border-color);
}

.topBarColumn {
  display: flex;
  align-items: flex-end;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;
}
