.list {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 75vh;
}

.listHorizontaLine {
  border-bottom: 3px solid var(--bs-primary);
  opacity: 100;
}

.horizontalLine {
  border-bottom: 1px solid var(--bs-border-color);
  opacity: 100;
}
