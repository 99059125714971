.icon {
  color: var(--bs-primary);
}

h1 {
  color: var(--bs-primary);
  font-weight: bold;
  font-size: 1.5em;
}

.offcanvasCustom {
  --bs-offcanvas-width: 75%;
}

.titleContainer {
  height: calc(110px - 51px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  height: 110px;
}

.content {
  flex: 1 1 auto;
  overflow: hidden;
  display: block;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.customDropdown {
  box-shadow: 0 0 0 0.1rem rgba(35, 92, 158, 0.25);
  border: 2px solid var(--bs-primary-border-subtle);
}
