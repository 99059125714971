.iconRed {
  @extend .iconClickable;

  color: var(--bs-danger);
  padding: 0.3em;
  border-radius: 50%;
}

.iconClickable {
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.3s ease;
  }
}

.iconGreen {
  @extend .iconClickable;
  color: var(--bs-success);
  padding: 0.3em;
  border-radius: 50%;
}

.iconPrimary {
  @extend .iconClickable;
  color: var(--bs-primary);
  padding: 0.3em;
  border-radius: 50%;
}

.customHover {
  &:hover {
    border: var(--bs-primary) solid 2px;
  }
}

:global {
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-bottom: 4px solid var(--bs-primary);
    margin-bottom: -4px;
    color: var(--bs-primary);
  }

  .nav-tabs .nav-link {
    border: none;
    color: lightgray;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    &:hover {
      color: var(--bs-primary);
    }
  }
}

.tableHeader {
  border-bottom: solid 3px rgba(0, 0, 0, 0.14);
}

.tableRow {
  border-bottom: solid 1px rgba(0, 0, 0, 0.14);
  vertical-align: middle;
}

.tableHeader th {
  color: rgba(0, 0, 0, 0.6);
  align-content: end;
}

.zoomableImage {
  &:hover {
    cursor: pointer;
    opacity: 0.75;
    transition: 0.25s ease;
  }
}

.thumbnailImage {
  width: 50px;
  height: 50px;
}

.alignCenter {
  display: flex;
  justify-content: center;
  text-align: center;
}

td {
  border-style: none !important;
}

.positionFixed {
  position: fixed;
}
