.uploadContainer {
  border-style: dashed;
  border-width: 2px;
  border-color: var(--bs-primary);
}

.deleteIcon {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.3em;
  border-radius: 50%;
}

.deleteIcon:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 0.3s ease;
}
