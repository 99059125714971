.subItemsTableContainer {
  border-left: 3px solid var(--bs-primary);
}

.verticalLine {
  width: 3px;
  border-left: 3px solid var(--bs-primary);
  height: 100%;
  opacity: 100;
}

.horizontalLine {
  border-bottom: 1px solid var(--bs-border-color);
  min-height: 100%;
  opacity: 100;
}

.invisibleVerticalLine {
  width: 3px;
  border-left: 3px solid var(--bs-primary);
  opacity: 0;
}

@keyframes highlightAnimation {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: var(--bs-primary);
    color: white;
  }
  100% {
    background-color: transparent;
  }
}

.highlight {
  animation: highlightAnimation 1s ease;
}

.redText {
  color: var(--bs-danger);
  font-weight: 900;
}

.greenText {
  color: #0cde0c;
  font-weight: 900;
}

.mobileVerticalLineDiv {
  width: 3%;
  padding: 0 !important;
}

.mobileCraftingRowContentDiv {
  width: 97%;
  margin: 0;
}
